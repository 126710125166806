$primary: #dc2812;

/* MAIN COLOR */

$secondary: #56b1d8;

/* SECONDARY COLOR */

$blk: #333;

/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */

$wht: #fafafa;
$border-radius: 0px;

/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */

$footerLinks: #fafafa;
html,
body {
    height: 100%;
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
}

p,
ol,
ul {
    font-family: 'Palanquin', sans-serif;
    font-size: 1.25em;
    letter-spacing: 1px;
    line-height: 25px;
}

a {
    font-family: 'Racing Sans One', cursive;
}

h1 {
    font-family: 'Racing Sans One', cursive;
    font-size: 4em;
    @media (max-width: 1024px) {
        font-size: 3em;
    }
}

h2 {
    font-family: 'Racing Sans One', cursive;
    font-size: 3em;
}

h3 {
    font-family: 'Racing Sans One', cursive;
    font-size: 2em;
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

.navbar-default {
    background: #F4F4F4;
    border-bottom: 1px solid $blk;
}


/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border-radius: $border-radius;
    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 5px;
            display: flex;
            align-items: center;
            color: $blk;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
    font-size: 1.5em;
    @media (max-width: 1024px) {
        font-size: 1.25em;
    }
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .navbar-toggle {
        margin-top: 5px;
    }
}

.top-pad {
    padding: 3em;
    background: white;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}

.terms,
.privacy {
    p {
        font-size: 15px;
    }
    & ol {
        li {
            font-size: 1em;
        }
        & ol li {
            font-size: 0.75em;
        }
    }
}


/* ========= BUTTONS ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited {
    background-color: #fca600;
    border-color: $wht;
    color: $wht;
    padding: 10px 20px;
    font-size: 19px;
    border-radius: 12px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.btn-download:hover {
    background-color: $primary;
    color: $wht;
}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
    margin-top: 50px;
    @media (max-width: 767px) {
        margin-top: 10px;
    }
    @media (max-width: 1024px) {
        margin-top: 10px;
    }
}


/* =============== HEADER ================ */

.header-overlay {
    background: rgba(31, 31, 31, .75);
}

.header-left {
    background: url('../img/banner-2.jpg');
    background-repeat: no-repeat;
    background-position: 80% 0%;
    background-size: cover;
    padding: 125px 100px;
    min-height: 640px;
    max-height: 640px;
    @media (max-width: 1440px) {
        padding: 0px;
    }
}

.header-right {
    background: url('../img/banner.jpg');
    background-repeat: no-repeat;
    background-position: 100% 0%;
    background-size: cover;
    min-height: 640px;
    max-height: 640px;
    @media (max-width: 1440px) {
        background-position: 65% 0%;
    }
}

.header-left-overlay {
    background: rgba(31, 31, 31, .85);
    padding: 75px;
    border-radius: 12px;
    @media (max-width: 1440px) {
        padding: 199px 26px;
        min-height: 640px;
        max-height: 640px;
    }
    @media (max-width: 767px) {
        padding: 100px 26px;
        min-height: 640px;
        max-height: 640px;
    }
}

.header h1 {
    color: $wht;
    text-shadow: 2px 2px $secondary;
    margin: 0px;
}

.header p {
    color: $wht;
}


/* =============== SECTION ONE ================ */

.section-one {
    padding: 50px 0px;
}

@media (max-width: 1199px) {
    .text-right {
        text-align: right;
    }
}

@media (max-width: 767px) {
    .text-right {
        text-align: center;
    }
}

.section-one h1 {
    margin: 0px;
    color: $primary;
    text-shadow: 2px 2px $secondary;
}

.section-one h2 {
    margin-top: 25px;
    color: $secondary;
}


/* =============== SECTION TWO ================ */

.section-two {
    background: url('../img/banner-2.jpg');
    background-repeat: no-repeat;
    background-position: 100% 50%;
    background-size: cover;
}

.section-two-overlay {
    background: rgba(31, 31, 31, .75);
    padding: 100px 0px;
}

.section-two h1 {
    color: $wht;
    text-shadow: 2px 2px $secondary;
}

.section-two p {
    color: $wht;
}


/* =============== SECTION THREE ================ */

.section-three {
    padding: 50px 0px;
    @media (max-width: 1199px) {
        text-align: left;
    }
    @media (max-width: 767px) {
        text-align: center;
    }
}

.section-three h3 {
    color: $primary;
}


/* =============== SECTION FOUR ================ */

.section-four {
    background: url('../img/banner-2.jpg');
    background-repeat: no-repeat;
    background-position: 100% 50%;
    background-size: cover;
}

.section-four-overlay {
    background: rgba(31, 31, 31, .75);
    padding: 100px 0px;
}

.section-four h1 {
    color: $wht;
    text-shadow: 2px 2px $secondary;
}

.section-four p {
    color: $wht;
}


/* =============== SECTION FIVE ================ */

.section-five {
    padding: 50px 0px;
    @media (max-width: 1199px) {
        text-align: left;
    }
    @media (max-width: 767px) {
        text-align: center;
    }
}

.section-five h3 {
    color: $primary;
}